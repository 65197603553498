import { createSlice } from '@reduxjs/toolkit'

export const questionSlice = createSlice({
	name: 'question',
	initialState: {
        question: {
            list: [],
            valid: null,
        }, 
        questionId:{}
    },
	reducers: {
        setQuestionList: (state, action) => {
            state.question.list = action.payload?.data
        },
        setQuestionId: (state, action) => {
            state.questionId = action.payload?.data
        },
	},
})

export const { 
    setQuestionList, 
    setQuestionId
} = questionSlice.actions

export default questionSlice.reducer