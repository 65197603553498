import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import company from './company/companySlice'
import employee from './employee/employeeSlice'
import pageHome from './pages/home/homeSlice'
import division from './division/divisionSlice'
import question from './question/questionSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        company,
        employee,
        pageHome,
        division,
        question,
        base,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
