import { createSlice } from '@reduxjs/toolkit'

export const divisionSlice = createSlice({
	name: 'division',
	initialState: {
        division: {
            list: [],
            meta: {}
        }, 
        divisionId:{}
    },
	reducers: {
        setDivisionList: (state, action) => {
            state.division.list = action.payload?.data
            state.division.meta = action.payload?.meta
        },
        setDivisionId: (state, action) => {
            state.divisionId = action.payload?.data
        },
	},
})

export const { 
    setDivisionId,
    setDivisionList,
} = divisionSlice.actions

export default divisionSlice.reducer