import { useSelector, useDispatch } from 'react-redux'
import { setAuthority, setUser } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp, apiUserMe } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { ADMIN, SUPERADMIN } from 'constants/roles.constant'

function useAuth() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

	const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)
    const { dataUser } = useSelector((state) => state.auth.user)

    const signIn = async (values) => {
        try {
			const resp = await apiSignIn(values)
			if (resp.data && (resp.data?.data?.role === ADMIN || resp.data?.data?.role === SUPERADMIN)) {
				const { token } = resp.data
				dispatch(onSignInSuccess(token))
				if(resp.data.data) {
					dispatch(setUser(resp?.data?.data))
					dispatch(setAuthority([resp?.data?.data?.role]))
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
                return {
                    status: 'success',
                    message: ''
                }
			}else{
				return {
					status: 'failed',
					message: 'login failed'
				}
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString()
            }
		}
    }

	const userMe = async () => {
        try {
			const resp = await apiUserMe()
			if (resp.data) {
				const {data} = resp
				
				if(data.data) {
					const tempData = {
						email : data?.data?.email,
						name : data?.data?.name,
						username: data?.data?.username,
						role: data?.data?.role?.name,
						company: data?.data?.company,
						division: data?.data?.divisions
					}
					
					dispatch(setUser(tempData))
					dispatch(setAuthority([tempData?.role]))
				}
                return {
                    status: 'success',
                    message: '',
					data: data.data
                }
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString()
            }
		}
    }

	const signUp = async (values) => {
        try {
			const resp = await apiSignUp(values)
			if (resp.data) {
				const { token } = resp.data
				dispatch(onSignInSuccess(token))
				if(resp.data.user) {
					dispatch(setUser(resp.data.user || { 
						avatar: '', 
						userName: 'Anonymous', 
						authority: ['USER'], 
						email: ''
					}))
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
                return {
                    status: 'success',
                    message: ''
                }
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString()
            }
		}
    }

    const handleSignOut = ()  => {
		dispatch(onSignOutSuccess())
		dispatch(setUser({}))
		dispatch(setAuthority([]))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

    const signOut = async () => {
		await apiSignOut()
		handleSignOut()
	}
    
    return {
        authenticated: token && signedIn,
        signIn,
		signUp,
        signOut,
		dataUser,
		userMe
    }
}

export default useAuth