import { createSlice } from '@reduxjs/toolkit'

export const homeSlice = createSlice({
	name: 'pageHome',
	initialState: {
        sectionList: [], 
    },
	reducers: {
        setHomeSectionList: (state, action) => {
            state.sectionList = action.payload?.data
        },
	},
})

export const { 
    setHomeSectionList,
} = homeSlice.actions

export default homeSlice.reducer