import { createSlice } from '@reduxjs/toolkit'

export const employeeSlice = createSlice({
	name: 'employee',
	initialState: {
        employee: {
            list: [],
            meta: {}
        }, 
        division: {
            list: [],
        }, 
        role: {
            list: [],
        }, 
        employeeId:{}
    },
	reducers: {
        setEmployeeList: (state, action) => {
            state.employee.list = action.payload?.data
            state.employee.meta = action.payload?.meta
        },
        setDivisionList: (state, action) => {
            state.division.list = action.payload?.data
        },
        setRoleList: (state, action) => {
            state.role.list = action.payload?.data
        },
        setEmployeeId: (state, action) => {
            state.employeeId = action.payload?.data
        },
	},
})

export const { 
    setEmployeeList, 
    setEmployeeId,
    setDivisionList,
    setRoleList
} = employeeSlice.actions

export default employeeSlice.reducer