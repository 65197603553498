import { createSlice } from '@reduxjs/toolkit'

export const companySlice = createSlice({
	name: 'company',
	initialState: {
        company: {
            list: [],
            meta: {}
        }, 
        companyId:{}
    },
	reducers: {
        setCompanyList: (state, action) => {
            state.company.list = action.payload?.data
            state.company.meta = action.payload?.meta
        },
        setCompanyId: (state, action) => {
            state.companyId = action.payload?.data
        },
	},
})

export const { 
    setCompanyList, 
    setCompanyId
} = companySlice.actions

export default companySlice.reducer